<script setup>
import { PROPERTY_TYPES } from '@/config/property-types';
import { WdsNavLink } from '@wds/components';

defineProps({
  active: {
    type: String,
    required: false,
    default: 'hostel',
  },
});
const { t } = useI18n();
const { useUrl } = useUrls();

// @TODO: When changing language, links here are being disabled after page load
const propertyTypes = computed(() => {
  return PROPERTY_TYPES.map((propertyType) => {
    const linkUrl = useUrl.getReplacementForPropertyTypeFromUrl({ propertyTypeSlug: t(propertyType?.slug) });
    return {
      ...propertyType,
      link: linkUrl,
    };
  });
});
</script>

<template>
  <div
    class="property-type-nav-links"
    role="tablist"
  >
    <WdsNavLink
      v-for="propType in propertyTypes"
      :key="propType.id"
      :name="propType.key"
      :label="$t(propType.transcode)"
      :title="$t(propType.transcode)"
      :active="active === propType.key"
      :link="propType.link"
      :disabled="null"
    />
  </div>
</template>

<style lang="scss" scoped>
.property-type-nav-links {
  display: inline-flex;
  gap: $wds-spacing-s;
}
</style>
